<template>
  <div v-if="($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.professor == 'S') && $store.state.fastPermissoes.ativo == 'S'">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
              >Modo professor</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Revisões de redação</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Revisões de
                  <span>redação</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <!-- conteudo principal -->
        <section class="container">
          <div v-if="exibeConteudoPrincipal">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                <h3>Revisões pendentes (3)</h3>
              </div>
              <div
                v-for="index in 3"
                :key="index"
                class="col-sm-12 col-md-12 col-lg-12 mb-2"
              >
                <div class="card mb-2 ">
                  <div class="card-header bg-ligth text-dark">
                    <i class="fas fa-info-circle text-secondary mr-1" /> Recebida em 11/01/2020 11:55
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                        <div
                          class="mr-2"
                          :style="{
                            backgroundImage:
                              'url(' +
                              ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                              ')',
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%',
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '50%',
                          }"
                        /> Emerson Santos
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                        <div>
                          <h6 class="mb-0">
                            Disciplina
                          </h6>
                          <small>Desenvolvimento de sistemas</small>
                        </div>                      
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                        <button
                          class="btn btn-sm btn-primary"
                          @click="
                            (exibeConteudoSecundario = true),
                            (exibeConteudoPrincipal = false)
                          "
                        >
                          <i class="fas fa-eye fa2-x" /> Revisar
                        </button>
                      </div>
                    </div>   
                    <div class="row mt-4">
                      <div class="col-12">
                        <p class="card-text">
                          Praesent finibus, enim vitae placerat fermentum, mi felis tempor magna, ut fringilla libero lorem non justo. Pellentesque nec velit dictum est laoreet faucibus eu eu quam. Maecenas in vulputate tortor, vel vehicula erat... 
                          <a href="">Ver mais</a>
                        </p>
                      </div>
                    </div>                
                  </div>
                </div>
              </div>    
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                <h3>Revisões Concluídas (3)</h3>
              </div>
              <div
                v-for="index in 3"
                :key="index"
                class="col-sm-12 col-md-12 col-lg-12 mb-2"
              >
                <div class="card mb-2 ">
                  <div class="card-header bg-secondary text-white">
                    Respondida em 11/01/2020 11:55
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                        <div
                          class="mr-2"
                          :style="{
                            backgroundImage:
                              'url(' +
                              ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                              ')',
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%',
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '50%',
                          }"
                        /> Emerson Santos
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                        <div>
                          <h6 class="mb-0">
                            Disciplina
                          </h6>
                          <small>Desenvolvimento de sistemas</small>
                        </div>                      
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                        <button
                          class="btn btn-sm btn-secondary"
                          @click="
                            (exibeConteudoSecundario = true),
                            (exibeConteudoPrincipal = false)
                          "
                        >
                          <i class="fas fa-eye fa2-x" /> Ver Revisão
                        </button>
                      </div>
                    </div>   
                    <div class="row mt-4">
                      <div class="col-12">
                        <p class="card-text">
                          Praesent finibus, enim vitae placerat fermentum, mi felis tempor magna, ut fringilla libero lorem non justo. Pellentesque nec velit dictum est laoreet faucibus eu eu quam. Maecenas in vulputate tortor, vel vehicula erat... 
                          <a href="">Ver mais</a>
                        </p>
                      </div>
                    </div>                
                  </div>
                </div>
              </div>    
            </div>
          </div>

          <div v-if="exibeConteudoSecundario">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                <h3>Detalhes da redação</h3>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                <div class="card mb-2 ">
                  <div class="card-header bg-ligth text-dark">
                    <i class="fas fa-info-circle mr-1" /> Recebida em 11/01/2020 11:55
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                        <div
                          class="mr-2"
                          :style="{
                            backgroundImage:
                              'url(' +
                              ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                              ')',
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%',
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '50%',
                          }"
                        /> Emerson Santos
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                        <div>
                          <h6 class="mb-0">
                            Disciplina
                          </h6>
                          <small>Desenvolvimento de sistemas</small>
                        </div>                      
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                        <div class="text-center">
                          <button class="btn btn-sm btn-success mb-2">
                            <i class="fas fa-check-circle fa2-x" /> Aceitar Redação
                          </button>
                          <button class="btn btn-sm btn-danger mb-2">
                            <i class="fas fa-times-circle fa2-x" /> Recusar Redação
                          </button>
                        </div>                      
                      </div>
                    </div>   
                    <div class="row mt-4">
                      <div class="col-12">
                        <p class="card-text">
                          Praesent finibus, enim vitae placerat fermentum, mi felis tempor magna, ut fringilla libero lorem non justo. Pellentesque nec velit dictum est laoreet faucibus eu eu quam. Maecenas in vulputate tortor, vel vehicula erat. Ut ante magna, egestas sed lobortis vitae, scelerisque vel ipsum. Donec nibh velit, mollis sit amet tincidunt id, iaculis sit amet nulla. Etiam odio lorem, convallis eget volutpat a, posuere sed tellus. Maecenas at lectus nec enim sodales hendrerit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent molestie mollis lorem non fermentum. Proin eu risus gravida ligula varius aliquam sit amet et elit. Cras quis velit eros. Pellentesque aliquet vel ipsum id faucibus. Fusce ornare ut dolor sed molestie. In id efficitur ligula. Ut condimentum, libero non congue cursus, ante sem malesuada nulla, eget euismod ligula arcu non neque.
                        </p>
                        <p class="card-text">
                          Etiam et auctor lectus. Donec semper mauris a quam mollis, hendrerit hendrerit magna dictum. Duis tincidunt magna non aliquam placerat. Curabitur sollicitudin condimentum nisl at pretium. Curabitur eu posuere nibh, non gravida turpis. Sed fringilla sapien sapien, sed faucibus sem semper vel. Maecenas malesuada augue et erat tempor, sed tristique massa aliquet. Duis quis purus eu arcu imperdiet aliquet. Donec elementum, libero id pharetra sagittis, magna ante tincidunt libero, et faucibus nunc lectus ac risus. Etiam sagittis vestibulum felis quis scelerisque.
                        </p>
                        <p class="card-text">
                          Aenean velit neque, luctus id arcu ut, tempus tempor tellus. Nulla rutrum, leo sed laoreet porttitor, tortor arcu pellentesque sapien, efficitur varius purus dui vel ligula. Pellentesque consectetur vel libero ut varius. Proin elementum vel dui eu vehicula. Phasellus non dui erat. Pellentesque vitae dignissim lectus, nec gravida ipsum. Curabitur sollicitudin turpis lacus, eget tincidunt ante porttitor eu. Morbi id purus non mauris dictum tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Ut vitae metus pretium, bibendum turpis sed, dapibus lacus. In sed nunc nisl. Sed eget convallis lorem, et volutpat neque. Sed facilisis elit viverra dignissim sollicitudin.
                        </p>
                      </div>
                    </div>                
                  </div>
                </div>
              </div>    
            </div>
            <div class="card-body shadow rounded p-4">
              <div class="row">
                <div class="col-12 mb-2 text-center">
                  <h3>Revisão</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                  <b-card no-body>
                    <b-tabs card>
                      <b-tab
                        title="Competências"
                        active
                      >
                        <b-card-text>
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-3 border">
                              #
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-9 border">
                              Nota
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 border">
                              <small>Competência 1</small>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-9 border">
                              <label><input type="radio">1</label>
                              <label class="ml-2"><input type="radio"> 2</label>
                              <label class="ml-2"><input type="radio"> 3</label>
                              <label class="ml-2"><input type="radio"> 4</label>
                              <label class="ml-2"><input type="radio"> 5</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 border">
                              <small>Competência 2</small>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-9 border">
                              <label><input type="radio">1</label>
                              <label class="ml-2"><input type="radio"> 2</label>
                              <label class="ml-2"><input type="radio"> 3</label>
                              <label class="ml-2"><input type="radio"> 4</label>
                              <label class="ml-2"><input type="radio"> 5</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 border">
                              <small>Competência 3</small>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-9 border">
                              <label><input type="radio">1</label>
                              <label class="ml-2"><input type="radio"> 2</label>
                              <label class="ml-2"><input type="radio"> 3</label>
                              <label class="ml-2"><input type="radio"> 4</label>
                              <label class="ml-2"><input type="radio"> 5</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 border">
                              <small>Competência 4</small>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-9 border">
                              <label><input type="radio">1</label>
                              <label class="ml-2"><input type="radio"> 2</label>
                              <label class="ml-2"><input type="radio"> 3</label>
                              <label class="ml-2"><input type="radio"> 4</label>
                              <label class="ml-2"><input type="radio"> 5</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 border">
                              <small>Competência 5</small>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-9 border">
                              <label><input type="radio">1</label>
                              <label class="ml-2"><input type="radio"> 2</label>
                              <label class="ml-2"><input type="radio"> 3</label>
                              <label class="ml-2"><input type="radio"> 4</label>
                              <label class="ml-2"><input type="radio"> 5</label>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="Comentario">
                        <b-card-text>
                          <quill-editor :options="editorOption" />
                        </b-card-text>
                      </b-tab>
                      <b-tab title="Anexar Arquivo">
                        <b-card-text>
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-4 text-center">
                              <div class="col-sm-12 col-md-12 col-lg-12 mt-4 text-center">
                                <label>Arraste ou clique para escolheros arquivos:</label>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12 text-center">
                  <button
                    class="btn btn-primary"                        
                  >
                    Responder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- conteudo principal -->
        <div
          v-if="exibeConteudoPrincipal"
          class="container"
        >
          <div class="mt-5 mb-5">
            <a
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma + '/professor'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>
        </div>

        <div
          v-else
          class="container"
        >
          <div class="mt-5 mb-5">
            <button
              class="btn btn-red-hollow"
              @click="exibeConteudoPrincipal = true, exibeConteudoSecundario = false"
            >
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "HomeInternoProfessorRevisoesRedacao",
  components: {
    quillEditor,
  },
  mixins: [methods],
  data: function () {
    return {
      exibeConteudoPrincipal: true,
      exibeConteudoSecundario: false,
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
          ],
        },
      },  
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'professor')
        .then(() => {
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    
  },
};
</script>


<style scope>
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
</style>
